import "styles/pages/not-found.scss";

import * as React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Layout from "components/Layout";

const NotFoundPage = ({ data }) => {
  const header_setup = {logo:'logo_black.svg', fontColor:'#000000', navbarColor: '#ffffff'};

  const not_found_page_data = data.pageNotFound?.nodes[0]?.pageNotFound;

  return (
    <Layout seo={{ title: "404: Not found" }} header_setup={header_setup}>
      <div className="not-found container-fluid">
        <h1 className="section-title">{not_found_page_data.notfoundTitle}</h1>
        <div className="btn-main">
          <Link to={`${not_found_page_data.notfoundBtnLink}`}>{not_found_page_data.notfoundBtn}</Link>
        </div>
        <div className="not-found__error">404</div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    pageNotFound: allWpPage(filter: {databaseId: {eq: 613}}) {
      nodes {
        seo {
          metaDesc
          title
        }
        pageNotFound {
          notfoundBtn
          notfoundBtnLink
          notfoundTitle
        }
      }
    }
  }
`

export default NotFoundPage
